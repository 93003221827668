<template id="players">
  <b-container fluid>
    <div v-if="waiting" class="text-center">
      <img :src="loadingImg" style="width: 150px" />
      <h4>Loading...</h4>
    </div>
    <div v-if="!waiting && !view_campo">
      <b-row align-v="center">
        <b-col>
          <h5 class="mt-2">
            Players ({{ total + total_no_opta }})
            <b-button v-b-toggle.collapse1 class="ml-2" size="sm"
              >Filter</b-button
            >
            <b-button v-b-toggle.collapse2 class="ml-2" size="sm"
              >Lists</b-button
            >
            <b-button
              class="ml-2"
              size="sm"
              v-if="auth.isUserEnable('player_add')"
              to="/scouting/new/player"
              target="_blank"
              >Add player</b-button
            >
            <!--b-button v-if="auth.isUserEnable('opta')" :variant="$store.state.extraValueIndex ? 'danger' : 'warning'" class="ml-2" size="sm" @click="$store.commit('toggleIndex')">{{ $store.state.extraValueIndex ? 'Switch to Index' : 'Switch to Extra Value Index'}}</b-button-->
          </h5>
        </b-col>
        <b-col class="text-right">
          <b-form-checkbox
            v-if="auth.isUserEnable('opta')"
            v-model="$store.state.extraValueIndex"
            >Extra Value Index</b-form-checkbox
          >
        </b-col>
      </b-row>
      <b-collapse id="collapse1" class="mt-2">
        <b-row class="mb-1">
          <b-col sm="2">
            <b-form-select v-model="filters.ruolo">
              <option value="0">-- Role --</option>
              <option v-for="item in ruoli" :value="item.id" :key="item.id">
                {{ item.ruolo }}
              </option>
            </b-form-select>
          </b-col>
          <b-col sm="2">
            <b-input-group>
              <b-form-input
                type="text"
                v-model="filters.search_name"
                placeholder="Last/first name"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="filtraArchivio"
                  ><b-icon icon="search"></b-icon
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col sm="2">
            <b-form-select v-model="filters.anno" :options="anni">
            </b-form-select>
          </b-col>
          <b-col sm="2">
            <!--b-form-select v-model="filters.non_valutati">
              <option value="0">-- Only evaluated --</option>
              <option value="1">Not evaluated</option>
              <option value="2">All players</option>
            </b-form-select-->
            <b-form-select v-model="filters.non_valutati">
              <option value="0">-- All players --</option>
              <option value="1">Only evaluated</option>
              <option value="2">Not evaluated</option>
            </b-form-select>
          </b-col>
          <b-col sm="2">
            <b-form-select v-model="filters.osservatore">
              <option value="0">-- Scout --</option>
              <option
                v-for="item in osservatori"
                :value="item.id"
                :key="item.id"
              >
                {{ item.cognome }}
              </option>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row
          class="mt-2 mb-1"
          v-if="auth.isUserEnable('opta')"
          align-v="center"
        >
          <b-col sm="3">
            <multiselect
              v-model="filters.opta_teams"
              :multiple="true"
              :options="opta_teams"
              placeholder="Opta teams"
              label="name"
              track-by="id"
            />
          </b-col>
          <b-col sm="3">
            <multiselect
              v-model="filters.opta_leagues"
              :multiple="true"
              :options="opta_leagues"
              placeholder="Opta leagues"
              label="name"
              track-by="id"
            />
          </b-col>
          <b-col sm="3">
            <multiselect
              v-model="filters.opta_clusters"
              :multiple="true"
              :options="opta_clusters"
              placeholder="Opta clusters"
              label="name"
              track-by="id"
            />
          </b-col>
          <b-col sm="1">
            <b-button @click="filtraArchivio"
              ><b-icon icon="search"></b-icon
            ></b-button>
          </b-col>
        </b-row>
      </b-collapse>
      <b-collapse id="collapse2" class="mt-2">
        <b-row class="mb-1">
          <b-col cols="5">
            <b-row>
              <b-col cols="7">
                <b-form-select
                  v-model="lista_selected"
                  :options="liste"
                  value-field="id"
                  text-field="nome"
                />
                <b-collapse id="collapse3" ref="collapse3" class="mb-1">
                  <b-form-input
                    v-model="new_lista"
                    type="text"
                    placeholder="Insert new list"
                  ></b-form-input>
                  <b-btn variant="primary" size="sm" @click="aggiungiLista"
                    >Save</b-btn
                  >
                </b-collapse>
                <b-collapse id="collapse4" ref="collapse4" class="mb-1">
                  <b-form-checkbox-group stacked v-model="lista_utenti">
                    <b-form-checkbox
                      v-for="item in osservatori"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.nomeCompleto }}</b-form-checkbox
                    >
                  </b-form-checkbox-group>
                  <b-btn variant="primary" size="sm" @click="condividiLista"
                    >Save</b-btn
                  >
                </b-collapse>
              </b-col>
              <b-col>
                <b-btn
                  class="mr-2"
                  variant="danger"
                  title="Delete list"
                  @click="eliminaLista"
                  v-if="!is_lista_master && !is_lista_condivisa"
                  ><b-icon icon="dash"></b-icon
                ></b-btn>
                <b-btn
                  class="mr-2"
                  variant="primary"
                  title="Add list"
                  v-b-toggle="'collapse3'"
                  ><b-icon icon="plus"></b-icon
                ></b-btn>
                <b-btn
                  class="mr-2"
                  variant="secondary"
                  title="Share list"
                  v-b-toggle="'collapse4'"
                  v-if="auth.isAdmin() && !is_lista_condivisa"
                  ><b-icon icon="people-fill"></b-icon
                ></b-btn>
                <b-btn
                  variant="info"
                  title="List in the field"
                  @click="listaInCampo"
                  ><b-icon icon="eye-fill"></b-icon
                ></b-btn>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="3">
            <div v-if="auth.isUserEnable('opta')">
              <b-row align-v="center">
                <b-col cols="6">
                  <b-form-checkbox id="checkConfronto" v-model="confronta">
                    Confronta
                  </b-form-checkbox>
                </b-col>
                <b-col cols="2">
                  <b-btn
                    variant="primary"
                    title="Show compare"
                    @click="showConfronto"
                    ><b-icon icon="search"></b-icon
                  ></b-btn>
                </b-col>
                <b-col cols="2">
                  <b-btn
                    variant="secondary"
                    title="Reset compare"
                    @click="resetConfronto"
                    ><b-icon icon="bootstrap-reboot"></b-icon
                  ></b-btn>
                </b-col>
                <b-col cols="2">
                  <b-btn
                    variant="info"
                    title="Lists compare"
                    @click="listeConfronto"
                    ><b-icon icon="list"></b-icon
                  ></b-btn>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-collapse>

      <v-infinite-scroll
        :loading="loading"
        @bottom="nextPage"
        :offset="20"
        style="max-height: 80vh; overflow-y: scroll"
        class="row"
      >
        <b-col
          :sm="auth.isUserEnable('opta') ? '6' : '4'"
          :md="auth.isUserEnable('opta') ? '6' : '4'"
          :lg="auth.isUserEnable('opta') ? '4' : '3'"
          v-for="(player, index) in players"
          :key="index"
          class="p-0 m-0 pb-2"
        >
          <player-card
            v-if="player.lastName"
            :skills="skills"
            :player="player"
            :ruolo="getRuoloWithSkill(player)"
            @addGiocatoreLista="addGiocatoreLista"
            @delGiocatoreLista="delGiocatoreLista"
            :editable="!is_lista_condivisa"
            :in_lista="
              player.giocatore
                ? lista_giocatori.indexOf(player.giocatore.id) > -1
                : false
            "
            :confronta="isModeConfronto"
            @editConfronto="editConfronto"
            :da_confrontare="inListaConfronto(player) > -1"
          />
          <player-no-opta-card
            v-else
            :player="player"
            @addGiocatoreLista="addGiocatoreLista"
            @delGiocatoreLista="delGiocatoreLista"
            :editable="!is_lista_condivisa"
            :in_lista="lista_giocatori.indexOf(player.id) > -1"
          />
        </b-col>
        <div v-if="loading" align="center" style="width: 100%">
          <img :src="loadingImg" style="width: 150px" />
        </div>
      </v-infinite-scroll>
    </div>

    <div class="card" v-if="view_campo">
      <div class="card-body">
        <div class="card-title">
          <span>List {{ lista_selected_label }}</span>
        </div>
        <div class="content">
          <b-row class="mb-1">
            <b-col cols="2">
              <b-form-select v-model="sistema_gioco">
                <option value="0">-- Game systems --</option>
                <option
                  v-for="item in sistemi_gioco_enabled"
                  :value="item.id"
                  :key="'mod_' + item.id"
                >
                  {{ item.sistema_gioco }}
                </option>
              </b-form-select>
            </b-col>
            <b-col cols="1">
              <b-button
                variant="primary"
                :title="
                  formazione_id ? 'Update formation' : 'Save new formation'
                "
                @click="salvaFormazioneLista"
                class="ml-1"
              >
                <b-icon :icon="formazione_id ? 'pencil' : 'plus'"></b-icon>
              </b-button>
            </b-col>
            <b-col cols="3">
              <b-form-group
                v-if="auth.isAdmin()"
                label="Title"
                :label-cols="2"
                horizontal
              >
                <b-form-input type="text" v-model="form.titolo"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                v-if="auth.isAdmin()"
                label="Date"
                :label-cols="2"
                horizontal
              >
                <b-form-input type="date" v-model="form.data"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="1"> </b-col>
            <b-col cols="2">
              <b-button
                v-if="auth.isAdmin()"
                variant="secondary"
                title="Archivio PDF"
                @click="archivioPdfLista"
                class="mr-2"
                ><b-icon icon="file-text"></b-icon>
              </b-button>
              <b-button
                variant="info"
                title="Back to players"
                @click="backArchivio"
                ><b-icon icon="box-arrow-right"></b-icon>
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="auth.isAdmin()">
            <b-col cols="3">
              <b-form-group label="From" :label-cols="2" horizontal>
                <b-form-input
                  type="date"
                  v-model="form.live_dal"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="To" horizontal :label-cols="2">
                <b-form-input type="date" v-model="form.live_al"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <div style="position: absolute; z-index: 1">
            <b-container>
              <small>Not on the field</small>
              <draggable
                :list="form_non_in_campo"
                :group="{ name: 'giocatori' }"
                style="min-height: 50px; background: #dddddd"
              >
                <b-row
                  v-for="giocatore in form_non_in_campo"
                  :key="giocatore.ig"
                  :class="
                    'm-0 ' +
                    (formazione_ids.indexOf(giocatore.id) === -1
                      ? 'bg-warning'
                      : '')
                  "
                >
                  <b-col class="notInCampo p-0"
                    ><b-link
                      :href="
                        auth.isUserEnable('opta') && giocatore.opta_player
                          ? '/scouting/view/player/' + giocatore.opta_player.id
                          : '/scouting/eval/player/' + giocatore.id
                      "
                      target="_blank"
                      style="color: black"
                      ><span style="color: #0000ff">{{
                        giocatore.cognome
                          ? giocatore.cognome.toUpperCase()
                          : "UNKNOWN"
                      }}</span>
                      <span style="color: #ff6600">{{
                        giocatore.data_nascita | formatYear
                      }}</span>
                      <span v-if="giocatore && giocatore.osservato">
                        <span
                          v-for="val in giocatore.osservato.valutazioni"
                          :key="val.id"
                        >
                          <span
                            v-if="showGrad(val)"
                            :title="getTitleGrad(val)"
                            :style="'background:' + val.gradimento.colore"
                          >
                            <grad1
                              class="grad_sm"
                              v-if="val.gradimento_id == 1"
                            />
                            <grad2
                              class="grad_sm"
                              v-if="val.gradimento_id == 2"
                            />
                            <grad3
                              class="grad_sm"
                              v-if="val.gradimento_id == 3"
                            />
                            <grad4
                              class="grad_sm"
                              v-if="val.gradimento_id == 4"
                            />
                            <grad5
                              class="grad_sm"
                              v-if="val.gradimento_id == 5"
                            />
                          </span>
                        </span>
                      </span>
                      {{
                        giocatore.squadra_militante
                          ? giocatore.squadra_militante.name
                          : ""
                      }}
                    </b-link></b-col
                  ></b-row
                >
              </draggable>
            </b-container>
          </div>
          <div
            :class="'sistema sistema-' + sistema_gioco"
            ref="field_lista"
            id="field_lista"
          >
            <div class="campo">
              <campo />
            </div>
            <div v-if="sistema_gioco > 0">
              <DraggableDiv
                v-for="(item, key) in form_in_campo"
                :class="'player player-' + moduli_ruoli[sistema_gioco][key - 1]"
                :key="'players_' + key"
              >
                <template slot="header">
                  <span class="label-value">{{
                    getNomeRuolo(moduli_ruoli[sistema_gioco][key - 1])
                  }}</span>
                </template>
                <template slot="main">
                  <b-container>
                    <draggable
                      :list="item"
                      :group="{ name: 'giocatori' }"
                      style="min-height: 50px"
                    >
                      <b-row
                        :key="giocatore.id"
                        v-for="giocatore in item"
                        :class="
                          formazione_ids.indexOf(giocatore.id) === -1
                            ? 'bg-warning'
                            : ''
                        "
                        ><b-col class="truncate p-0"
                          ><b-link
                            :href="
                              auth.isUserEnable('opta') && giocatore.opta_player
                                ? '/scouting/view/player/' +
                                  giocatore.opta_player.id
                                : '/scouting/eval/player/' + giocatore.id
                            "
                            target="_blank"
                            style="
                              color: black;
                              background: transparent !important;
                            "
                            ><span style="color: #0000ff">{{
                              giocatore.cognome
                                ? giocatore.cognome.toUpperCase()
                                : "UNKNOWN"
                            }}</span>
                            <span style="color: #ff6600">{{
                              giocatore.data_nascita | formatYear
                            }}</span>
                            <span v-if="giocatore && giocatore.osservato">
                              <span
                                v-for="val in giocatore.osservato.valutazioni"
                                :key="val.id"
                              >
                                <span
                                  v-if="showGrad(val)"
                                  :title="getTitleGrad(val)"
                                  :style="'background:' + val.gradimento.colore"
                                >
                                  <grad1
                                    class="grad_sm"
                                    v-if="val.gradimento_id == 1"
                                  />
                                  <grad2
                                    class="grad_sm"
                                    v-if="val.gradimento_id == 2"
                                  />
                                  <grad3
                                    class="grad_sm"
                                    v-if="val.gradimento_id == 3"
                                  />
                                  <grad4
                                    class="grad_sm"
                                    v-if="val.gradimento_id == 4"
                                  />
                                  <grad5
                                    class="grad_sm"
                                    v-if="val.gradimento_id == 5"
                                  />
                                </span>
                              </span>
                            </span>

                            {{
                              giocatore.squadra_militante
                                ? giocatore.squadra_militante.name
                                : ""
                            }}</b-link
                          ></b-col
                        ></b-row
                      >
                    </draggable>
                  </b-container>
                </template>
              </DraggableDiv>
            </div>
          </div>
          <b-row class="mt-2" v-if="auth.isAdmin() || auth.isOsservCapo()">
            <b-col>
              <b-table
                small
                striped
                bordered
                dark
                responsive
                hover
                :items="items_lista"
                :fields="fields_lista"
                sort-by="age"
                :sort-desc="true"
                ref="tbl_lista"
                id="tbl_lista"
              >
                <template #cell(age)="data">
                  <strong>{{ data.item["label"] }}</strong>
                </template>
                <template #cell()="data">
                  <h5 style="margin-bottom: 0px">
                    <b-badge style="width: 100%" variant="success">{{
                      data.item[data.field.key]
                    }}</b-badge>
                  </h5>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row v-if="auth.isAdmin()" align-h="center" class="mt-5">
            <b-col cols="10">
              <b-form-group label="Note" :label-cols="1">
                <b-form-textarea
                  v-model="form.note"
                  :rows="3"
                  :max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button v-if="auth.isAdmin()" variant="primary" @click="salvaPdf"
            >Save PDF</b-button
          >
          <b-button
            v-if="auth.isAdmin() || auth.isOsservCapo()"
            variant="info"
            class="ml-2"
            title="Export Table"
            @click="pdfTableLista"
          >
            Export table <b-icon icon="file-text"></b-icon>
          </b-button>
          <b-button
            v-if="auth.isAdmin() || auth.isOsservCapo()"
            variant="warning"
            class="ml-2"
            title="Export Field"
            @click="pdfFieldLista"
          >
            Export field <b-icon icon="file-text"></b-icon>
          </b-button>
        </div>
      </div>
    </div>

    <b-modal ref="pdfModal" title="Archive PDF" ok-only ok-title="Close">
      <b-table
        :items="elenco_pdf"
        :fields="[
          { key: 'titolo', label: 'Title' },
          { key: 'data', formatter: this.formatDate },
          { key: 'actions', label: 'Actions' },
        ]"
      >
        <template v-slot:cell(actions)="row">
          <b-button
            variant="primary"
            class="mr-2"
            size="sm"
            title="download PDF"
            @click="downloadPdf(row.item.id)"
          >
            <b-icon icon="download"></b-icon>
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            title="Delete PDF"
            @click="eliminaPdf(row.item.id)"
          >
            <b-icon icon="trash"></b-icon>
          </b-button>
        </template>
      </b-table>
    </b-modal>

    <b-modal
      ref="confrontoModal"
      title="Compare players"
      size="xl"
      ok-only
      ok-title="Close"
    >
      <b-table
        striped
        hover
        small
        responsive
        :items="items"
        :fields="fields"
        style="font-size: 0.85rem; background: #ffffff; color: #000000"
        v-model="table_confronto"
        foot-clone
        ref="tbl_confronto"
        id="tbl_confronto"
      >
        <template v-slot:[getSkillHead(skill)] v-for="skill in fields_skills">
          <span :key="skill.id" :title="skill.label"> {{ skill.name }}</span>
        </template>
        <template v-slot:foot(giocatore)>
          <span title="Average">Average</span>
        </template>
        <template v-slot:foot(ruolo)>
          <span></span>
        </template>
        <template v-slot:foot(classe)>
          <span>{{ medie_confronto["classe"] }}</span>
        </template>
        <template v-slot:foot(piede)>
          <span></span>
        </template>
        <template v-slot:[getSkillFoot(skill)] v-for="skill in fields_skills">
          <span :key="skill.id" :title="skill.label">{{
            medie_confronto[skill.name]
          }}</span>
        </template>
      </b-table>
      <b-row class="mt-2">
        <b-col>
          <b-form-group label="Title" horizontal :label-cols="2">
            <b-form-input v-model="title_confronto"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-button variant="primary" title="Export PDF" @click="pdfConfronto"
            ><b-icon icon="file-text"></b-icon>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      ref="listeConfrontoModal"
      title="Comparison lists"
      ok-only
      ok-title="Close"
    >
      <b-row class="m-2">
        <b-col cols="9">
          <b-form-input
            v-model="nuova_lista_confronto"
            placeholder="New list"
          ></b-form-input>
        </b-col>
        <b-col>
          <b-button variant="primary" title="Save" @click="addListaConfronto">
            Add
          </b-button>
        </b-col>
      </b-row>
      <b-table
        :items="liste_confronto"
        :fields="[
          { key: 'nome', label: 'Name' },
          { key: 'created_at', label: 'Date', formatter: this.formatDate },
          'actions',
        ]"
      >
        <template v-slot:cell(actions)="row">
          <b-button
            class="mr-1"
            size="sm"
            variant="info"
            title="Load list"
            @click="loadListaConfronto(row.item.id)"
          >
            <b-icon icon="list"></b-icon>
          </b-button>
          <b-button
            class="mr-1"
            size="sm"
            variant="success"
            title="Update list"
            @click="updateListaConfronto(row.item.id)"
          >
            <b-icon icon="pencil"></b-icon>
          </b-button>
          <b-button
            size="sm"
            variant="danger"
            title="Delete list"
            @click="deleteListaConfronto(row.item.id)"
            ><b-icon icon="trash"></b-icon
          ></b-button>
        </template>
      </b-table>
    </b-modal>
  </b-container>
</template>

<script>
import Auth from "@/lib/auth.js";
import moment from "moment";
import $ from "jquery";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Multiselect from "vue-multiselect";
import PlayerCard from "@/components/PlayerCard.vue";
import PlayerNoOptaCard from "@/components/PlayerNoOptaCard.vue";
import DraggableDiv from "@/components/DraggableDiv.vue";
import loadingImg from "@/assets/images/loading.gif";
import campo from "@/assets/images/campo.svg";
import InfiniteScroll from "v-infinite-scroll";
import draggable from "vuedraggable";
import grad1 from "@/assets/images/grad1.svg";
import grad2 from "@/assets/images/grad2.svg";
import grad3 from "@/assets/images/grad3.svg";
import grad4 from "@/assets/images/grad4.svg";
import grad5 from "@/assets/images/grad5.svg";

export default {
  components: {
    PlayerCard,
    PlayerNoOptaCard,
    DraggableDiv,
    campo,
    draggable,
    Multiselect,
    grad1,
    grad2,
    grad3,
    grad4,
    grad5,
    "v-infinite-scroll": InfiniteScroll,
  },

  data: function () {
    return {
      waiting: true,
      loading: false,
      //   osservati: [],
      //   osservati_no_opta: [],
      players: [],
      total: 0,
      total_no_opta: 0,
      opta_length: 0,
      page: 1,

      skills: [],
      skills_old: [],
      ruoli: [],
      filters: {
        ruolo: 0,
        gradimento: 0,
        osservatore: 0,
        search_name: "",
        anno: "",
        voto_medio: "",
        voto_medio_ruolo: "",
        segnalatore: 0,
        stato: "",
        non_valutati: 0,
        note: 0,
        valutati_dal: "",
        valutati_al: "",
        lista: 0,
        live: 0,
        squadra: 0,
        periodo: 0,
        appunti: 0,
        nazionale: 0,
        opta_teams: [],
        opta_leagues: [],
        opta_clusters: [],
      },
      loadingImg,
      liste: [],
      lista_selected: 0,
      lista_selected_label: "",
      new_lista: "",
      lista_giocatori: [],
      lista_utenti: [],
      gradimenti: [],
      osservatori: [],
      view_campo: false,
      sistemi_gioco: [],
      sistema_gioco: 4,
      lista_in_campo: "",
      moduli_ruoli: {
        1: [1, 8, 4, 3, 7, 18, 16, 15, 17, 24, 25] /* 4-4-2 */,
        2: [1, 8, 4, 3, 7, 11, 16, 15, 14, 24, 25] /* 4-4-2 rombo*/,
        3: [1, 8, 4, 3, 7, 18, 16, 15, 17, 19, 24] /* 4-4-1-1 */,
        4: [6, 8, 9, 15, 14, 7, 5, 13, 12, 4, 18] /* 4-3-3 */,
        5: [1, 6, 2, 5, 18, 16, 15, 17, 23, 25, 22] /* 3-4-3 */,
        6: [1, 6, 2, 5, 10, 16, 11, 15, 9, 24, 25] /* 3-5-2 */,
        7: [1, 8, 4, 3, 7, 16, 15, 21, 19, 20, 24] /* 4-2-3-1 */,
        8: [1, 8, 4, 3, 7, 13, 11, 12, 19, 26, 25] /* 4-3-2-1 */,
        9: [1, 8, 4, 3, 7, 16, 11, 15, 19, 24, 25] /* 4-3-1-2 */,
        10: [1, 8, 4, 3, 7, 11, 13, 14, 12, 24, 25] /* 4-1-3-2 */,
        11: [1, 8, 4, 3, 7, 11, 18, 16, 15, 17, 24] /* 4-1-4-1 */,
        12: [1, 6, 2, 5, 18, 16, 15, 17, 19, 23, 22] /* 3-4-1-2 */,
        13: [6, 9, 1, 15, 8, 10, 16, 14, 7, 13, 4] /* 3-4-2-1 */,
        14: [1, 10, 6, 2, 5, 9, 16, 15, 23, 24, 22] /* 5-2-3 */,
        15: [1, 10, 6, 2, 5, 9, 13, 11, 12, 24, 25] /* 5-3-2 */,
        16: [1, 10, 6, 2, 5, 9, 18, 16, 15, 17, 24] /* 5-4-1 */,
      },
      form_in_campo: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
      },
      form_non_in_campo: [],
      formazione_id: null,
      formazione_ids: [],
      form: {
        titolo: "",
        data: "",
        note: "",
        live_dal: null,
        live_al: null,
      },
      elenco_pdf: [],
      confronta: false,
      lista_confronto: [],
      dati_confronto: {},
      range_confronto: {},
      table_confronto: [],
      title_confronto: "",
      liste_confronto: [],
      nuova_lista_confronto: "",
      medie_confronto: {},
      fasi: [1, 3, 2, 4],
      opta_teams: [],
      opta_leagues: [],
      opta_clusters: [],
      principles_title: [
        "PRESSING",
        "RECOVERY",
        "MARKING",
        "BUILD UP",
        "POSSESSION",
        "ENGAGEMENT",
        "UNMARKING",
        "DRIBBLING",
        "WIDE PLAY",
        "FINISHING",
        "CREATIVITY",
        "GOALKEEPER",
        "SET PLAY",
      ],
    };
  },

  created: function () {
    if (this.auth.isUserEnable("opta")) {
      this.getSkills();
      //this.getOldSkills();
      this.getOptaTeams();
      this.getOptaLeagues();
      this.getOptaClusters();
    } else {
      this.getPlayers();
    }
    this.getSistemi();
    this.getRuoli();
    this.getListe();
    this.getListeConfronto();
    this.getOsservatori();
  },

  methods: {
    getOldSkills() {
      //  this.waiting = true;
      //  this.getPlayers();

      this.waiting = true;
      this.$http.get("/opta/skills/range").then((response) => {
        this.skills_old = response.data;
        //   this.getPlayers();
      });
    },

    getSkills() {
      //  this.waiting = true;
      //  this.getPlayers();

      this.waiting = true;
      this.$http.get("/opta/new/skills/range").then((response) => {
        this.skills = response.data;
        this.getPlayers();
      });
    },

    getSkillsWithFilter() {
      //  this.waiting = true;
      //  this.filtraArchivio();

      this.waiting = true;
      this.$http
        .get("/opta/skills/range", { params: this.filters })
        .then((response) => {
          this.skills = response.data;
          this.filtraArchivio();
        });
    },

    getRuoli() {
      var url = "/ruoli";
      if (this.auth.isUserEnable("opta")) {
        url = "/opta/ruoli/skills";
      }
      this.$http.get(url).then((response) => {
        this.ruoli = response.data;
      });
    },

    getSistemi() {
      this.$http.get("/sistemi").then((response) => {
        this.sistemi_gioco = response.data;
      });
    },

    getOptaTeams() {
      this.$http.get("/opta/teams").then((response) => {
        this.opta_teams = response.data;
      });
    },

    getOptaLeagues() {
      this.$http.get("/opta/leagues").then((response) => {
        this.opta_leagues = response.data;
      });
    },

    getOptaClusters() {
      this.$http.get("/opta/clusters").then((response) => {
        this.opta_clusters = response.data;
      });
    },

    getPlayers() {
      this.waiting = true;
      this.$http.get("/scouting/archivio/giocatori").then((response) => {
        var osservati = response.data.giocatori.data;
        var osservati_no_opta = response.data.giocatori_no_opta.data;
        this.total = response.data.giocatori.total;
        this.total_no_opta = response.data.giocatori_no_opta.total;
        this.opta_length = osservati.length;

        var all_players = [];
        var to_sort = false;
        if (osservati.length > 0 && osservati_no_opta.length > 0) {
          all_players = osservati.concat(osservati_no_opta);
          to_sort = true;
        } else if (osservati.length > 0) {
          all_players = osservati;
        } else if (osservati_no_opta.length > 0) {
          all_players = osservati_no_opta;
        }
        if (to_sort) {
          if (
            this.auth.isAdmin() ||
            this.auth.isOsservCapo() ||
            this.user.id == 13
          ) {
            all_players.sort(function (a, b) {
              return new Date(b.view_at) - new Date(a.view_at);
            });
          } else {
            all_players.sort(function (a, b) {
              var nameA = a.lastName
                ? a.giocatore
                  ? a.giocatore.cognome
                  : a.lastName
                : a.cognome;
              var nameB = b.lastName
                ? b.giocatore
                  ? b.giocatore.cognome
                  : b.lastName
                : b.cognome;
              return nameA - nameB;
            });
          }
        }
        this.players = all_players;
        this.waiting = false;
      });
    },

    getRuoloWithSkill(player) {
      if (player.ruolo_id) {
        for (var i in this.ruoli) {
          var ruolo = this.ruoli[i];
          if (ruolo.id == player.ruolo_id) {
            return ruolo;
          }
        }
      }
      return null;
    },

    nextPage() {
      if (this.opta_length < this.total) {
        this.page++;
        this.loading = true;
        this.$http
          .get("/scouting/archivio/giocatori?page=" + this.page, {
            params: this.filters,
          })
          .then((response) => {
            var osservati = response.data.giocatori.data;
            var osservati_no_opta = response.data.giocatori_no_opta.data
              ? response.data.giocatori_no_opta.data
              : response.data.giocatori_no_opta;
            this.opta_length = this.opta_length + osservati.length;
            var all_players = [];
            var to_sort = false;
            if (osservati.length > 0 && osservati_no_opta.length > 0) {
              all_players = osservati.concat(osservati_no_opta);
              to_sort = true;
            } else if (osservati.length > 0) {
              all_players = osservati;
            } else if (osservati_no_opta.length > 0) {
              all_players = osservati_no_opta;
            }
            if (to_sort) {
              if (
                this.auth.isAdmin() ||
                this.auth.isOsservCapo() ||
                this.user.id == 13
              ) {
                all_players.sort(function (a, b) {
                  return new Date(b.view_at) - new Date(a.view_at);
                });
              } else {
                all_players.sort(function (a, b) {
                  var nameA = a.lastName
                    ? a.giocatore
                      ? a.giocatore.cognome
                      : a.lastName
                    : a.cognome;
                  var nameB = b.lastName
                    ? b.giocatore
                      ? b.giocatore.cognome
                      : b.lastName
                    : b.cognome;
                  return nameA - nameB;
                });
              }
            }

            if (all_players.length > 0) {
              this.players = this.players.concat(all_players);
            }

            this.loading = false;
          });
      }
    },

    filtraArchivio() {
      this.waiting = true;
      this.loading = true;
      this.players = [];
      this.page = 1;
      this.$http
        .get("/scouting/archivio/giocatori", { params: this.filters })
        .then((response) => {
          var osservati = response.data.giocatori.data;
          var osservati_no_opta = response.data.giocatori_no_opta.data
            ? response.data.giocatori_no_opta.data
            : response.data.giocatori_no_opta;
          this.total = response.data.giocatori.total;
          this.total_no_opta = response.data.giocatori_no_opta.total
            ? response.data.giocatori_no_opta.total
            : 0;
          this.opta_length = osservati.length;

          var all_players = osservati.concat(osservati_no_opta);
          if (
            this.auth.isAdmin() ||
            this.auth.isOsservCapo() ||
            this.user.id == 13
          ) {
            all_players.sort(function (a, b) {
              return new Date(b.view_at) - new Date(a.view_at);
            });
          } else {
            all_players.sort(function (a, b) {
              var nameA = a.lastName ? a.lastName : a.cognome;
              var nameB = b.lastName ? b.lastName : b.cognome;
              if (nameA > nameB) {
                return 1;
              }
              if (nameB > nameA) {
                return -1;
              }
              return 0;
            });
          }
          this.players = all_players;
          this.loading = false;
          this.waiting = false;
        });
    },

    getListe() {
      this.$http.get("/liste").then((response) => {
        this.liste = response.data;
        this.lista_selected = this.liste[0].id;
        this.lista_selected_label = this.liste[0].nome;
      });
    },

    getListeConfronto() {
      this.$http.get("/liste/confronto").then((response) => {
        this.liste_confronto = response.data;
      });
    },

    getOsservatori() {
      this.$http.get("/osservatori").then((response) => {
        this.osservatori = response.data;
      });
    },

    aggiungiLista() {
      if (this.new_lista == "") {
        alert("Insert list name!");
        return;
      }
      this.$http
        .get("/scouting/add/lista/" + this.new_lista)
        .then((response) => {
          this.$refs.collapse3.toggle();
          this.new_lista = "";
          this.$store.commit("msgPush", {
            msg: "List added succesfully!",
            status: 1,
          });
          this.liste = response.data;
        });
    },

    eliminaLista() {
      if (confirm("Are you sure you want to delete the selected list?")) {
        this.$http
          .get("/scouting/delete/lista/" + this.lista_selected)
          .then((response) => {
            this.$store.commit("msgPush", {
              msg: "List deleted succesfully!",
              status: 1,
            });
            this.liste = response.data;
            this.lista_selected = this.liste[0].id;
            this.lista_selected_label = this.liste[0].nome;
          });
      }
    },

    condividiLista() {
      this.$http
        .get(
          "/scouting/condividi/lista/" +
            this.lista_selected +
            "/" +
            this.lista_utenti
        )
        .then((response) => {
          this.$refs.collapse4.toggle();
          this.$store.commit("msgPush", {
            msg: "List shared succesfully!",
            status: 1,
          });
          this.liste = response.data;
          this.lista_selected = this.liste[0].id;
          this.lista_selected_label = this.liste[0].nome;
        });
    },

    addGiocatoreLista(id) {
      if (!this.is_lista_condivisa) {
        this.$http
          .get(
            "/scouting/add/giocatore/lista/" + this.lista_selected + "/" + id
          )
          .then((response) => {
            this.$store.commit("msgPush", {
              msg: "Player added to list!",
              status: 1,
            });
            this.liste = response.data;
            this.lista_giocatori.push(id);
          });
      }
    },

    delGiocatoreLista(id) {
      if (!this.is_lista_condivisa) {
        this.$http
          .get(
            "/scouting/delete/giocatore/lista/" + this.lista_selected + "/" + id
          )
          .then((response) => {
            this.$store.commit("msgPush", {
              msg: "Player deleted from list!",
              status: 1,
            });
            this.liste = response.data;
            var index = this.lista_giocatori.indexOf(id);
            this.lista_giocatori.splice(index, 1);
            if (this.filters.lista > 0) {
              this.filtraArchivio();
            }
          });
      }
    },

    listaInCampo() {
      this.$http
        .get("/scouting/lista/in/campo/" + this.lista_selected)
        .then((response) => {
          this.lista_in_campo = response.data;
          this.setFormazioneLista();
          this.view_campo = true;
        });
    },

    setFormazioneLista() {
      this.form_in_campo = {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
      };
      this.form_non_in_campo = [];
      this.formazione_ids = [];
      this.formazione_id = null;
      for (var i in this.lista_in_campo.formazioni) {
        if (
          this.lista_in_campo.formazioni[i].sistema_gioco_id ==
          this.sistema_gioco
        ) {
          this.formazione_id = this.lista_in_campo.formazioni[i].id;
          this.form_in_campo = JSON.parse(
            this.lista_in_campo.formazioni[i].in_campo
          );
          this.form_non_in_campo = JSON.parse(
            this.lista_in_campo.formazioni[i].non_in_campo
          );
          break;
        }
      }
      if (this.formazione_id) {
        for (var i in this.form_in_campo) {
          var array_gio = this.form_in_campo[i];
          for (var j in array_gio) {
            var id_gio = array_gio[j];
            for (var x in this.lista_in_campo.giocatori) {
              var giocatore = this.lista_in_campo.giocatori[x];
              if (giocatore.id == id_gio) {
                array_gio[j] = giocatore;
                this.formazione_ids.push(id_gio);
                break;
              }
            }
          }
        }
        for (var i in this.form_non_in_campo) {
          var id_gio = this.form_non_in_campo[i];
          for (var x in this.lista_in_campo.giocatori) {
            var giocatore = this.lista_in_campo.giocatori[x];
            if (giocatore.id == id_gio) {
              this.form_non_in_campo[i] = giocatore;
              this.formazione_ids.push(id_gio);
              break;
            }
          }
        }
      }
      for (var i in this.lista_in_campo.giocatori) {
        var giocatore = this.lista_in_campo.giocatori[i];
        if (this.formazione_ids.indexOf(giocatore.id) === -1) {
          var index = this.getPosizioneGiocatore(giocatore);
          if (index > -1) {
            this.form_in_campo[index + 1].push(giocatore);
          } else {
            this.form_non_in_campo.push(giocatore);
          }
        }
      }
    },

    getPosizioneGiocatore(giocatore) {
      var index = -1;
      var array_ruoli = this.moduli_ruoli[this.sistema_gioco];

      if (giocatore.ruolo_ideale && giocatore.ruolo_ideale.length > 0) {
        var ruolo_ideale = giocatore.ruolo_ideale[0];
        if (array_ruoli.indexOf(ruolo_ideale.id) > -1) {
          return array_ruoli.indexOf(ruolo_ideale.id);
        } else if (giocatore.altri_ruoli && giocatore.altri_ruoli.length > 0) {
          for (var i in giocatore.altri_ruoli) {
            var altro_ruolo_id = giocatore.altri_ruoli[i].id;
            if (array_ruoli.indexOf(altro_ruolo_id) > -1) {
              return array_ruoli.indexOf(altro_ruolo_id);
            }
          }
        }
      }
      return index;
    },

    salvaFormazioneLista() {
      var form_data = {
        form_in_campo: {
          1: [],
          2: [],
          3: [],
          4: [],
          5: [],
          6: [],
          7: [],
          8: [],
          9: [],
          10: [],
          11: [],
        },
        form_non_in_campo: [],
      };
      for (var key in this.form_in_campo) {
        var array_gio = this.form_in_campo[key];
        for (var i in array_gio) {
          var giocatore = array_gio[i];
          if (giocatore && giocatore instanceof Object) {
            form_data.form_in_campo[key].push(giocatore.id);
          }
        }
      }
      for (var i in this.form_non_in_campo) {
        var giocatore = this.form_non_in_campo[i];
        if (giocatore && giocatore instanceof Object) {
          form_data.form_non_in_campo.push(giocatore.id);
        }
      }
      this.$http
        .post(
          "/scouting/salva/formazione/lista/" +
            this.lista_selected +
            "/" +
            this.sistema_gioco,
          form_data
        )
        .then((response) => {
          this.$store.commit("msgPush", {
            msg: response.data.message,
            status: 1,
          });
          this.listaInCampo();
        });
    },

    backArchivio() {
      this.view_campo = false;
    },

    getNomeRuolo(id) {
      for (var i in this.ruoli) {
        var ruolo = this.ruoli[i];
        if (ruolo.id == id) {
          return ruolo.ruolo.toUpperCase();
        }
      }
      return "";
    },

    salvaPdf() {
      this.$http
        .post(
          "/scouting/salva/pdf/lista/" +
            this.lista_selected +
            "/" +
            this.sistema_gioco,
          this.form
        )
        .then((response) => {
          window.location =
            this.$store.state.apiEndPoint +
            "/scouting/download/pdf/lista/" +
            response.data +
            "/" +
            this.user.id +
            "/" +
            this.user.api_token;
        });
    },

    archivioPdfLista() {
      this.$http
        .get("/scouting/archivio/pdf/lista/" + this.lista_selected)
        .then((response) => {
          this.elenco_pdf = response.data;
          this.$refs.pdfModal.show();
        });
    },

    formatDate(data) {
      var m = moment(data);
      var format = m.format("DD/MM/YYYY");
      return format;
    },

    showGrad(val) {
      if (!this.auth.isAdmin()) {
        return false;
      }
      if (!val.gradimento) {
        return false;
      }
      if (!this.form.live_dal && !this.form.live_al) {
        return true;
      } else if (this.form.live_dal && this.form.live_al) {
        if (
          val.created_at >= this.form.live_dal + " 00:00:00" &&
          val.created_at <= this.form.live_al + " 23:59:59"
        ) {
          return true;
        }
      } else if (this.form.live_dal && !this.form.live_al) {
        if (val.created_at >= this.form.live_dal + " 00:00:00") {
          return true;
        }
      } else if (!this.form.live_dal && this.form.live_al) {
        if (val.created_at <= this.form.live_al + " 23:59:59") {
          return true;
        }
      }
      return false;
    },

    getTitleGrad(val) {
      var title = "";
      if (val.gradimento) {
        title =
          val.gradimento.nome +
          " del " +
          moment(val.created_at).format("DD/MM/YYYY") +
          " di " +
          val.utente.nickname;
      }
      return title;
    },

    downloadPdf(pdf) {
      window.location =
        this.$store.state.apiEndPoint +
        "/scouting/download/pdf/lista/" +
        pdf +
        "/" +
        this.user.id +
        "/" +
        this.user.api_token;
    },

    eliminaPdf(pdf) {
      if (confirm("Are you sure you want to delete the pdf?")) {
        this.$http
          .get("/scouting/elimina/pdf/lista/" + pdf)
          .then((response) => {
            this.elenco_pdf = response.data;
          });
      }
    },
    inListaConfronto(osservato) {
      for (var index in this.lista_confronto) {
        var item = this.lista_confronto[index];
        if (item.id == osservato.id) {
          return index;
        }
      }
      return -1;
    },

    editConfronto(osservato) {
      var index = this.inListaConfronto(osservato);
      if (index > -1) {
        this.lista_confronto.splice(index, 1);
      } else {
        this.lista_confronto.push(osservato);
      }
    },

    showConfronto() {
      this.$refs.confrontoModal.show();
    },

    resetConfronto() {
      this.lista_confronto = [];
    },

    listeConfronto() {
      this.$refs.listeConfrontoModal.show();
    },

    addListaConfronto: function () {
      if (this.nuova_lista_confronto != "") {
        this.$http
          .get("/add/lista/confronto/" + this.nuova_lista_confronto)
          .then((response) => {
            this.liste_confronto = response.data;
            this.nuova_lista_confronto = "";
          });
      }
    },

    loadListaConfronto: function (id) {
      this.$http
        .get("/load/lista/confronto/giocatori/" + id)
        .then((response) => {
          this.lista_confronto = response.data;
          this.$store.commit("msgPush", {
            msg: "Comparison list loaded successfully!",
            status: 1,
          });
          this.$refs.listeConfrontoModal.hide();
        });
    },

    updateListaConfronto: function (id) {
      var ids_gio = [];
      for (var i in this.lista_confronto) {
        var id_gio = this.lista_confronto[i].giocatore_id;
        ids_gio.push(id_gio);
      }
      this.$http
        .get("/edit/lista/confronto/giocatori/" + id + "/" + ids_gio)
        .then((response) => {
          this.$store.commit("msgPush", {
            msg: response.data.message,
            status: 1,
          });
        });
    },

    deleteListaConfronto: function (id) {
      if (
        confirm("Are you sure you want to delete the selected comparison list?")
      ) {
        this.$http.get("/delete/lista/confronto/" + id).then((response) => {
          this.liste_confronto = response.data;
        });
      }
    },

    classe: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("YYYY");
      }
      return "-";
    },

    creaDatiConfronto() {
      this.dati_confronto = {};
      for (var i in this.lista_confronto) {
        var osservato = this.lista_confronto[i];
        var giocatore = osservato.giocatore
          ? osservato.giocatore.cognome +
            " " +
            osservato.giocatore.nome.substr(0, 1) +
            "."
          : osservato.lastName + " " + osservato.firstName.substr(0, 1) + ".";
        var giocatori = [];
        if (i > 0) {
          var giocatori = this.dati_confronto["giocatore"];
        } else {
          this.dati_confronto["giocatore"] = giocatori;
        }
        giocatori.push(giocatore);

        var ruolo = osservato.ruolo
          ? osservato.ruolo.sigla
          : osservato.giocatore &&
            osservato.giocatore.ruolo_ideale &&
            osservato.giocatore.ruolo_ideale.length > 0
          ? osservato.giocatore.ruolo_ideale[0].sigla
          : "-";

        var ruoli = [];
        if (i > 0) {
          var ruoli = this.dati_confronto["ruolo"];
        } else {
          this.dati_confronto["ruolo"] = ruoli;
        }
        ruoli.push(ruolo);
        var classe = this.classe(
          osservato.giocatore
            ? osservato.giocatore.data_nascita
            : osservato.birthDate
        );
        var classi = [];
        if (i > 0) {
          var classi = this.dati_confronto["classe"];
        } else {
          this.dati_confronto["classe"] = classi;
        }
        classi.push(classe);
        var piede = osservato.giocatore
          ? osservato.giocatore.label_piede
          : "right";
        var piedi = [];
        if (i > 0) {
          var piedi = this.dati_confronto["piede"];
        } else {
          this.dati_confronto["piede"] = piedi;
        }
        piedi.push(piede);

        for (var y in this.standard_skills) {
          var skill = this.standard_skills[y];

          var value = "-";

          if (osservato.stats) {
            for (var x in osservato.stats) {
              var stat = osservato.stats[x];
              if (stat.skill_id == skill.id) {
                value = isNaN(stat.skill_value)
                  ? "-"
                  : Math.round(stat.skill_value * 100) / 100;
              }
            }
          }

          var array_opta = [];
          if (i > 0) {
            var array_opta = this.dati_confronto[skill.name];
          } else {
            this.dati_confronto[skill.name] = array_opta;
          }
          array_opta.push(value);
        }

        var micro = "-";
        var micro_color_rank = 0;
        var micros = [];
        var micros_color_rank = [];
        if (this.$store.state.extraValueIndex) {
          if (osservato.optaExtraValueIndex) {
            micro = osservato.optaExtraValueIndex.micro_index;
            micro_color_rank =
              osservato.optaExtraValueIndex.micro_index_color_rank;
          }
        } else {
          if (osservato.optaIndex) {
            micro = osservato.optaIndex.micro_index;
            micro_color_rank = osservato.optaIndex.micro_index_color_rank;
          }
        }
        if (i > 0) {
          var micros = this.dati_confronto["micro"];
          var micros_color_rank = this.dati_confronto["micro_color_rank"];
        } else {
          this.dati_confronto["micro"] = micros;
          this.dati_confronto["micro_color_rank"] = micros_color_rank;
        }
        micros.push(micro);
        micros_color_rank.push(micro_color_rank);

        var macro = "-";
        var macro_color_rank = 0;
        var macros = [];
        var macros_color_rank = [];
        if (this.$store.state.extraValueIndex) {
          if (osservato.optaExtraValueIndex) {
            macro = osservato.optaExtraValueIndex.macro_index;
            macro_color_rank =
              osservato.optaExtraValueIndex.macro_index_color_rank;
          }
        } else {
          if (osservato.optaIndex) {
            macro = osservato.optaIndex.macro_index;
            macro_color_rank = osservato.optaIndex.macro_index_color_rank;
          }
        }
        if (i > 0) {
          var macros = this.dati_confronto["macro"];
          var macros_color_rank = this.dati_confronto["macro_color_rank"];
        } else {
          this.dati_confronto["macro"] = macros;
          this.dati_confronto["macro_color_rank"] = macros_color_rank;
        }
        macros.push(macro);
        macros_color_rank.push(macro_color_rank);

        for (var y in this.principles_title) {
          var key = this.principles_title[y];
          var principle = "-";
          var principle_color_rank = 0;
          var principles = [];
          var principles_color_rank = [];
          if (this.$store.state.extraValueIndex) {
            if (osservato.optaExtraValueIndex) {
              var principles_index = JSON.parse(
                osservato.optaExtraValueIndex.principles_index
              );
              var principles_index_color_rank = JSON.parse(
                osservato.optaExtraValueIndex.principles_index_color_rank
              );
              principle = principles_index[y];
              principle_color_rank = principles_index_color_rank[y];
            }
          } else {
            if (osservato.optaIndex) {
              var principles_index = JSON.parse(
                osservato.optaIndex.principles_index
              );
              var principles_index_color_rank = JSON.parse(
                osservato.optaIndex.principles_index_color_rank
              );
              principle = principles_index[y];
              principle_color_rank = principles_index_color_rank[y];
            }
          }
          if (i > 0) {
            var principles = this.dati_confronto[key];
            var principles_color_rank = this.dati_confronto[
              key + "_color_rank"
            ];
          } else {
            this.dati_confronto[key] = principles;
            this.dati_confronto[key + "_color_rank"] = principles_color_rank;
          }
          principles.push(principle);
          principles_color_rank.push(principle_color_rank);
        }
      }
      this.settaRangeConfronto();
    },

    settaRangeConfronto() {
      this.range_confronto = {};
      this.medie_confronto = {};
      for (var key in this.dati_confronto) {
        var interval = 0;
        var array = this.dati_confronto[key].slice(0);
        array.sort(function (a, b) {
          if (!isNaN(a) && !isNaN(b)) {
            return b - a;
          } else if (isNaN(a) && !isNaN(b)) {
            return 1;
          } else if (!isNaN(a) && isNaN(b)) {
            return -1;
          } else {
            return 0;
          }
        });
        var count = array.length;
        if (count > 10) {
          interval = Math.floor(count / 10);
        }
        var array_range = [];
        var from = 0;
        var to = interval;
        for (var i = 0; i < 10; i++) {
          if (to < count) {
            var start = array[from];
            var end = array[to];
            var range = {};
            range["from"] = start;
            range["to"] = end;
            array_range.push(range);
            from = to;
            to = to + interval;
            if (interval == 0) {
              to++;
            }
          }
        }
        this.range_confronto[key] = array_range;
        var media = "";
        var tot = 0;
        var num = 0;
        for (var i in array) {
          if (!isNaN(array[i]) && array[i] > 0) {
            tot += parseFloat(array[i]);
            num++;
          }
        }
        if (num > 0) {
          media = tot / num;
          media = Math.round(media * 100) / 100;
          if (key == "classe") {
            media = Math.round(media);
          }
        }
        this.medie_confronto[key] = media;
      }
    },

    getVariantConfronto(value, key) {
      var variant = "";
      var array_range = this.range_confronto[key];
      if (array_range && array_range.length > 0 && value != "-") {
        for (var i = 0; i < array_range.length; i++) {
          if (i == 0) {
            if (value <= array_range[i].from && value >= array_range[i].to) {
              variant = "voto-" + (10 - i);
              break;
            }
          } else if (i == 9) {
            if (value < array_range[i].from) {
              variant = "voto-" + (10 - (i + 1));
              break;
            }
          } else {
            if (value < array_range[i].from && value >= array_range[i].to) {
              variant = "voto-" + (10 - i);
              break;
            }
          }
        }
      }
      return variant;
    },

    pdfConfronto() {
      var page_hratio =
        $("#tbl_confronto").get(0).clientHeight /
        $("#tbl_confronto").get(0).clientWidth;
      const options = {
        type: "dataURL",
      };
      html2canvas($("#tbl_confronto").get(0), options).then((canvas) => {
        var doc = new jsPDF("l", "mm", "a4");
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        //  doc.addImage(this.header_pdf, "JPG", 0, 0, width, 30);
        var y = 10;
        if (this.title_confronto != "") {
          doc.text(150, y, this.title_confronto, null, null, "center");
          y = 45;
        }
        doc.addImage(canvas, "PNG", 2, y, width - 4, width * page_hratio);
        doc.save("confronto.pdf");
      });
    },

    pdfTableLista() {
      var page_hratio =
        $("#tbl_lista").get(0).clientHeight /
        $("#tbl_lista").get(0).clientWidth;
      const options = {
        type: "dataURL",
      };
      window.scrollTo(0, 0);
      html2canvas($("#tbl_lista").get(0), options).then((canvas) => {
        var doc = new jsPDF("l", "mm", "a4");
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        var y = 10;
        /* if (this.lista_selected_label != "") {
      doc.text(150, y, this.lista_selected_label, null, null, "center");
      y = 45;
    } */
        doc.addImage(canvas, "PNG", 2, y, width - 4, width * page_hratio);
        doc.save("lista.pdf");
      });
    },

    pdfFieldLista() {
      var page_hratio =
        $("#field_lista").get(0).clientHeight /
        $("#field_lista").get(0).clientWidth;
      const options = {
        type: "dataURL",
      };
      $("#field_lista")
        .find("svg")
        .map(function () {
          var svg = $(this);
          svg.attr("width", svg.width());
          svg.attr("height", svg.height());
        });
      window.scrollTo(0, 0);
      html2canvas($("#field_lista").get(0), options).then((canvas) => {
        var doc = new jsPDF("l", "mm", "a4");
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        var y = 10;
        if (this.form.titolo != "") {
          doc.text(150, y, this.form.titolo, null, null, "center");
          y = 20;
        }
        doc.addImage(canvas, "PNG", 2, y, width - 4, width * page_hratio);
        doc.save("lista_field.pdf");
      });
    },

    getSkillFoot(skill) {
      return "foot(" + skill.name + ")";
    },

    getSkillHead(skill) {
      return "head(" + skill.name + ")";
    },

    isListaConfrontoHasSkill(skill) {
      for (var j in this.lista_confronto) {
        var osservato = this.lista_confronto[j];
        if (osservato.ruolo) {
          for (var x in this.ruoli) {
            var ruolo = this.ruoli[x];
            if (ruolo.id == osservato.ruolo.id) {
              for (var y in this.fasi) {
                for (var s in ruolo["opta_skills_fase" + this.fasi[y]]) {
                  var object = ruolo["opta_skills_fase" + this.fasi[y]][s];
                  if (object.id == skill.id) {
                    return true;
                  }
                }
              }
            }
          }
        } else if (
          osservato.giocatore &&
          osservato.giocatore.ruolo_ideale &&
          osservato.giocatore.ruolo_ideale.length > 0
        ) {
          for (var x in this.ruoli) {
            var ruolo = this.ruoli[x];
            if (ruolo.id == osservato.giocatore.ruolo_ideale[0].id) {
              for (var y in this.fasi) {
                for (var s in ruolo["opta_skills_fase" + this.fasi[y]]) {
                  var object = ruolo["opta_skills_fase" + this.fasi[y]][s];
                  if (object.id == skill.id) {
                    return true;
                  }
                }
              }
            }
          }
        }
      }
      return false;
    },
    age: function (date) {
      var birthday = moment(date);
      if (birthday.isValid()) {
        return moment().diff(birthday, "years");
      }
      return "";
    },
  },

  watch: {
    "filters.ruolo": function () {
      this.filtraArchivio();
    },
    "filters.anno": function () {
      this.filtraArchivio();
    },
    "filters.non_valutati": function () {
      this.filtraArchivio();
    },
    "filters.osservatore": function () {
      this.filtraArchivio();
    },
    /*  "filters.opta_teams": function() {
      this.getSkillsWithFilter();
    },
    "filters.opta_leagues": function() {
      this.getSkillsWithFilter();
    },
    "filters.opta_clusters": function() {
      this.getSkillsWithFilter();
    }, */
    lista_selected: function () {
      this.lista_giocatori = [];
      for (var index in this.liste) {
        var lista = this.liste[index];
        if (lista.id == this.lista_selected) {
          this.lista_selected_label = lista.nome;
          for (var i in lista.lista_giocatori) {
            var item = lista.lista_giocatori[i];
            this.lista_giocatori.push(item.giocatore_id);
          }
        }
      }
      this.lista_utenti = [];
      for (var index in this.liste) {
        var lista = this.liste[index];
        if (lista.id == this.lista_selected) {
          for (var i in lista.lista_utenti) {
            var item = lista.lista_utenti[i];
            this.lista_utenti.push(item.utente_id);
          }
        }
      }
    },
    sistema_gioco: function () {
      this.setFormazioneLista();
    },
    lista_confronto: function () {
      this.creaDatiConfronto();
    },
    "$store.state.extraValueIndex": function () {
      this.creaDatiConfronto();
    },
  },

  computed: {
    auth: function () {
      return Auth;
    },
    user: function () {
      return Auth.user;
    },
    anni: function () {
      var options = [];
      options.push({ value: "", text: "-- Year --" });
      var anno = moment().subtract(45, "years").format("YYYY");
      for (var i = 0; i <= 40; i++) {
        options.push({ value: anno, text: anno });
        anno++;
      }
      return options;
    },
    is_lista_master: function () {
      for (var index in this.liste) {
        var lista = this.liste[index];
        if (lista.id == this.lista_selected) {
          if (lista.master) {
            return true;
          }
        }
      }
      return false;
    },
    is_lista_condivisa: function () {
      for (var index in this.liste) {
        var lista = this.liste[index];
        if (lista.id == this.lista_selected) {
          if (lista.condivisa) {
            return true;
          }
        }
      }
      return false;
    },

    isModeConfronto() {
      return this.confronta;
    },

    standard_skills: function () {
      return this.skills.filter((item) => item.standard === 1);
    },

    no_standard_skills: function () {
      return this.skills.filter((item) => item.standard === 0);
    },

    fields_skills: function () {
      return this.standard_skills;
    },

    fields: function () {
      var fields = [
        {
          key: "giocatore",
          label: "Giocatore",
          sortable: true,
          tdClass: "td_comp_gio",
          thClass: "th_comp_gio",
        },
        {
          key: "ruolo",
          label: "Ruolo",
          sortable: true,
          tdClass: "td_comp_skill",
          thClass: "th_comp_skill",
        },
        {
          key: "classe",
          label: "Classe",
          sortable: true,
          tdClass: "td_comp_skill",
          thClass: "th_comp_skill",
        },
        {
          key: "piede",
          label: "Piede",
          sortable: true,
          tdClass: "td_comp_skill",
          thClass: "th_comp_skill",
        },
      ];
      for (var i in this.standard_skills) {
        var skill = this.standard_skills[i];
        var field = {
          key: skill.name,
          //  label: skill.label,
          label: skill.name,
          sortable: true,
          tdClass: "th_comp_skill",
          thClass: "th_comp_skill",
        };
        fields.push(field);
      }
      var field = {
        key: "micro",
        label: "MICRO",
        sortable: true,
        tdClass: "th_comp_skill",
        thClass: "th_comp_skill",
      };

      fields.push(field);
      var field = {
        key: "macro",
        label: "MACRO",
        sortable: true,
        tdClass: "th_comp_skill",
        thClass: "th_comp_skill",
      };
      fields.push(field);

      for (var i in this.principles_title) {
        var principle = this.principles_title[i];
        var field = {
          key: principle,
          label: principle.substr(0, 3),
          sortable: true,
          tdClass: "th_comp_skill",
          thClass: "th_comp_skill",
        };
        fields.push(field);
      }
      return fields;
    },

    items: function () {
      var items = [];
      for (var i = 0; i < this.lista_confronto.length; i++) {
        var item = {
          giocatore: this.dati_confronto["giocatore"][i],
          ruolo: this.dati_confronto["ruolo"][i],
          classe: this.dati_confronto["classe"][i],
          piede: this.dati_confronto["piede"][i],
        };

        for (var x in this.standard_skills) {
          var skill = this.standard_skills[x];
          item[skill.name] = this.dati_confronto[skill.name][i];
        }

        item["micro"] = this.dati_confronto["micro"][i];
        item["macro"] = this.dati_confronto["macro"][i];

        for (var y in this.principles_title) {
          var key = this.principles_title[y];
          item[key] = this.dati_confronto[key][i];
        }

        var variant_obj = {};
        variant_obj["classe"] = this.getVariantConfronto(
          this.dati_confronto["classe"][i],
          "classe"
        );

        for (var x in this.standard_skills) {
          var skill = this.standard_skills[x];
          variant_obj[skill.name] = this.getVariantConfronto(
            this.dati_confronto[skill.name][i],
            skill.name
          );
        }

        variant_obj["micro"] =
          "voto-" + (10 - this.dati_confronto["micro_color_rank"][i]);
        variant_obj["macro"] =
          "voto-" + (10 - this.dati_confronto["macro_color_rank"][i]);

        for (var y in this.principles_title) {
          var key = this.principles_title[y];
          variant_obj[key] =
            "voto-" + (10 - this.dati_confronto[key + "_color_rank"][i]);
        }

        item["_cellVariants"] = variant_obj;
        items.push(item);
      }
      return items;
    },

    fields_lista: function () {
      var fields = [
        {
          key: "age",
          label: "AGE",
          sortable: true,
          class: "text-center",
          tdClass: "td_lista",
          thStyle: { "font-size": "0.9rem" },
        },
      ];
      for (var key in this.form_in_campo) {
        var ruolo = this.getNomeRuolo(
          this.moduli_ruoli[this.sistema_gioco][key - 1]
        );
        var field = {
          key: key,
          label: ruolo,
          sortable: false,
          class: "text-center",
          tdClass: "td_lista",
          thClass: "th_lista",
        };
        fields.push(field);
      }
      return fields;
    },

    items_lista: function () {
      var items = [];
      for (var i in this.form_in_campo) {
        var players = this.form_in_campo[i];
        for (var p in players) {
          var player = players[p];
          var age = this.age(player.data_nascita);
          var item = items.find((obj) => obj.age === age && obj[i] == null);
          var index_age = items.findIndex((obj) => obj.age === age);
          if (typeof item !== "undefined") {
            item[i] = player.cognome ? player.cognome.toUpperCase() : "UNKNOWN";
          } else {
            var item = {
              age: age,
              1: null,
              2: null,
              3: null,
              4: null,
              5: null,
              6: null,
              7: null,
              8: null,
              9: null,
              10: null,
              11: null,
              label: index_age > -1 ? "" : age,
            };
            item[i] = player.cognome ? player.cognome.toUpperCase() : "UNKNOWN";
            items.push(item);
          }
        }
      }
      return items;
    },
    sistemi_gioco_enabled() {
      var ids = [4, 13];
      return this.sistemi_gioco.filter((item) => ids.includes(item.id));
    },
  },

  filters: {
    formatYear: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("YY");
      }
      return "";
    },
  },
};
</script>
